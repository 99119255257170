import React, { useState } from 'react';
import { RecordContextProvider, ShowButton, EditButton, ChipField, SingleFieldList, ReferenceArrayField, Link, useListContext } from 'react-admin';
import { Grid, Card, CardHeader, CardContent, IconButton as MUIIconButton, Menu, MenuItem, Box } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { green, orange, red, grey, blue } from '@mui/material/colors';

const menuItemSx = {
    padding: 0,
    '& a': {
        width: '100%',
        minHeight: '36px',
        justifyContent: 'flex-start',
        padding: '4px 10px'
    }
};

const getStatusBoxSx = ({ status }) => {
    let sx = { width: '6px', height: '100%', position: 'absolute' };
    switch (status) {
        case 'active':
            sx = { ...sx, backgroundColor: (theme) => theme.palette.mode === 'dark' ? green[700] : green[200] };
            break;
        case 'pending':
            sx = { ...sx, backgroundColor: (theme) => theme.palette.mode === 'dark' ? orange[700] : orange[200] };
            break;
        case 'maintenance':
            sx = { ...sx, backgroundColor: (theme) => theme.palette.mode === 'dark' ? blue[700] : blue[100] };
            break;
        case 'cancelled':
            sx = { ...sx, backgroundColor: (theme) => theme.palette.mode === 'dark' ? red[700] : red[200] };
            break;
        default:
            sx = { ...sx, backgroundColor: (theme) => theme.palette.mode === 'dark' ? grey[700] : grey[200] };
            break;
    }

    return sx;
};

const ActionButtons = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleOpen = (e) => {
        setAnchorEl(e.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <MUIIconButton onClick={handleOpen} size="small">
                <MoreVertIcon />
            </MUIIconButton>
            <Menu open={open} anchorEl={anchorEl} onClose={handleClose}>
                <MenuItem sx={menuItemSx}><ShowButton /></MenuItem>
                <MenuItem sx={menuItemSx}><EditButton /></MenuItem>
            </Menu>
        </>
    );
};

const ProjectGrid = () => {
    const { data } = useListContext();

    return (
        <Grid container spacing={2} sx={{ mt: 0 }}>
            {data?.map(record => (
                <RecordContextProvider key={record.id} value={record}>
                    <Grid key={record.id} item xs={12} sm={6} md={4} lg={3} xl={2}>
                        <Card sx={{ position: 'relative', height: '100%' }}>
                            <Box sx={getStatusBoxSx(record)} />
                            <CardHeader
                                title={<Link to={`/projects/${record.id}/show`} sx={{ textDecoration: 'none' }}>{record.name}</Link>}
                                subheader={record?.customer?.name || record?.customer?.short_name}
                                action={<ActionButtons />}
                            />
                            <CardContent>
                                <ReferenceArrayField source="user_ids" reference="users">
                                    <SingleFieldList sx={{ gap: '4px' }}>
                                        <ChipField source="trigram" variant="outlined" size="small" />
                                    </SingleFieldList>
                                </ReferenceArrayField>
                            </CardContent>
                        </Card>
                    </Grid>
                </RecordContextProvider>
            ))}
        </Grid>
    );
};

export default ProjectGrid;