import React from 'react';
import { Title, useTranslate, useGetIdentity } from 'react-admin';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import MUILink from '@mui/material/Link';
// import Typography from '@mui/material/Typography';
import PsychologyIcon from '@mui/icons-material/Psychology';
import CoPresentIcon from '@mui/icons-material/CoPresent';
import AddToDriveIcon from '@mui/icons-material/AddToDrive';
import GroupsIcon from '@mui/icons-material/Groups';

// import GroupsIcon from '@mui/icons-material/Groups';
// import DirectionsBoatIcon from '@mui/icons-material/DirectionsBoat';
// import TerminalIcon from '@mui/icons-material/Terminal';
// import BrushIcon from '@mui/icons-material/Brush';
// import SchoolIcon from '@mui/icons-material/School';

import IconCard from './common/IconCard';
import RedmineIcon from './common/icons/Redmine';


const Link = (props) => (
    <MUILink {...props} target="_blank" rel="noreferrer noopener" />
);

const UsefulLinks = () => {
    const translate = useTranslate();
    const { data: account } = useGetIdentity();

    return (
        <Box my={5}>
            <Title title="Useful links" />
            <Grid container spacing={2} sx={{ marginTop: '15px' }}>
                <Grid item lg={2} xl={2} sm={6} xs={12}>
                    <Link href="https://redmine.kern-it.be">
                        <IconCard
                            title={translate('Redmine')}
                            icon={<RedmineIcon />}
                            variant="rounded"
                            size="small"
                            color="#CE332C"
                        />
                    </Link>
                </Grid>
                <Grid item lg={2} xl={2} sm={6} xs={12}>
                    <Link href="https://docs.google.com/spreadsheets/d/1GVH-8q0jUoJlrDJPJyPNLSoJcq9u0ACIv10dL_D7qdU/edit">
                        <IconCard
                            title={translate('Follow-up')}
                            icon={<GroupsIcon />}
                            variant="rounded"
                            size="small"
                        />
                    </Link>
                </Grid>
                <Grid item lg={2} xl={2} sm={6} xs={12}>
                    <Link href="https://drive.google.com/drive/folders/11l-bjfB2UCCBmqWWhY_9zPxEkd--Qk-l">
                        <IconCard
                            title={translate('Braindumps')}
                            icon={<PsychologyIcon />}
                            variant="rounded"
                            size="small"
                        />
                    </Link>
                </Grid>
                <Grid item lg={2} xl={2} sm={6} xs={12}>
                    <Link href="https://docs.google.com/spreadsheets/d/1A1yTvnezNxphJSsC59sYkHAulhq3FrS_klY_hAmurUU/edit?gid=0#gid=0">
                        <IconCard
                            title={translate('KernTalk Schedule')}
                            icon={<CoPresentIcon />}
                            variant="rounded"
                            size="small"
                        />
                    </Link>
                </Grid>
            </Grid>

            <Grid container spacing={2} sx={{ marginTop: '15px' }}>
                {account?.documents_url && (
                    <Grid item lg={2} xl={2} sm={6} xs={12}>
                        <Link href={account?.documents_url}>
                            <IconCard
                                title={translate('My documents')}
                                icon={<AddToDriveIcon />}
                                variant="rounded"
                                size="small"
                                color={account?.color}
                            />
                        </Link>
                    </Grid>
                )}
            </Grid>

            {/* <Typography variant="h5" mt={5}>{translate('Rules')}</Typography>
            <Grid container spacing={2} sx={{ marginTop: '15px' }}>
                <Grid item lg={2} xl={2} sm={6} xs={12}>
                    <Link href="">
                        <IconCard
                            title={translate('Values')}
                            icon={<GroupsIcon />}
                            variant="rounded"
                            size="small"
                        />
                    </Link>
                </Grid>
                <Grid item lg={2} xl={2} sm={6} xs={12}>
                    <Link href="">
                        <IconCard
                            title={translate('Onboarding')}
                            icon={<DirectionsBoatIcon />}
                            variant="rounded"
                            size="small"
                        />
                    </Link>
                </Grid>
                <Grid item lg={2} xl={2} sm={6} xs={12}>
                    <Link href="">
                        <IconCard
                            title={translate('Developer rules')}
                            icon={<TerminalIcon />}
                            variant="rounded"
                            size="small"
                        />
                    </Link>
                </Grid>
                <Grid item lg={2} xl={2} sm={6} xs={12}>
                    <Link href="">
                        <IconCard
                            title={translate('Designer rules')}
                            icon={<BrushIcon />}
                            variant="rounded"
                            size="small"
                        />
                    </Link>
                </Grid>
            </Grid>

            <Typography variant="h5" mt={5}>{translate('Documentation')}</Typography>
            <Grid container spacing={2} sx={{ marginTop: '15px' }}>
                <Grid item lg={2} xl={2} sm={6} xs={12}>
                    <Link href="">
                        <IconCard
                            title={translate('Knowledge base')}
                            icon={<SchoolIcon />}
                            variant="rounded"
                            size="small"
                        />
                    </Link>
                </Grid>
            </Grid> */}
        </Box>
    );
};

export default UsefulLinks;