import React, { useState } from 'react';
import { useGetList, useTranslate } from 'react-admin';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import moment from 'moment';

import DateField from '../common/fields/DateField';
import IconCard from '../common/IconCard';
import LeaveCalendar from 'components/leaves/LeaveList/LeaveCalendar';

const NOW_DATE = moment().format('YYYY-MM-DD');

const calendarSx = {
    padding: 0,
    margin: 0,
    borderWidth: 0,
    height: '100%',
    '& table.fc-scrollgrid': {
        borderLeftWidth: 0,
        borderRightWidth: 0,
        borderBottomWidth: 0
    },
    '& .fc .fc-toolbar.fc-header-toolbar': {
        margin: '3px',
        '.fc-toolbar-title': {
            fontSize: '16px'
        },
        '.fc-button': {
            padding: '1px 3px',
            boxShadow: 'none !important',
            fontSize: '12px',
            lineHeight: '14px',
            '.fc-icon': {
                fontSize: '12px'
            }
        },
        '.fc-today-button': {
            fontSize: '11px'
        }
    },
    '& .fc .fc-col-header-cell-cushion': {
        padding: 0,
        fontSize: '12px'
    },
    '& .fc-event-title-container': {
        fontSize: '10px',
        lineHeight: '9px'
    },
    '& .fc .fc-daygrid-body-unbalanced .fc-daygrid-day-events': {
        minHeight: 0
    },
    '.fc .fc-scrollgrid-section-header > th, & .fc .fc-scrollgrid-section-liquid > td': {
        borderRightWidth: 0,
        borderBottomWidth: 0
    },
    '& .fc table': {
        fontSize: '12px'
    }
};
const calendarButton = {
    margin: '3px',
    marginTop: '8px',
    padding: '1px 3px',
    marginLeft: 'auto',
    backgroundColor: '#fff',
    color: 'rgba(0, 0, 0, 0.54)',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    fontSize: '12px',
    lineHeight: '14px',
    '& *': {
        margin: 0
    }
};

const NextLeaves = ({ data, setView }) => {
    const translate = useTranslate();

    const handleClickCalendar = () => {
        setView('calendar');
    }

    if (data?.length > 0) {
        return (
            <Box display="flex" flexDirection="column">
                <Button sx={calendarButton} onClick={handleClickCalendar}>{translate('Calendar')}</Button>
                <List dense sx={{ padding: 0 }}>
                    {data.map(record => (
                        <ListItemButton component={Link} to={`/leaves/${record.id}/show`} key={`next_leaves_${record.id}`}>
                            <ListItemText
                                primary={
                                    <>
                                        <DateField source="start_date" record={record} /> {record?.end_date !== record?.start_date && (<>- <DateField source="end_date" record={record} /></>)}
                                    </>
                                }
                                secondary={record?.user?.fullname}
                            />
                            <ListItemSecondaryAction>
                                <Box
                                    component="span"
                                    sx={{
                                        marginRight: '1em',
                                        color: 'text.primary',
                                    }}
                                >
                                    {record.duration}j
                                </Box>
                            </ListItemSecondaryAction>
                        </ListItemButton>
                    ))}
                </List>
            </Box>
        );
    }

    return null;
};

const Calendar = ({ setView, ...props }) => {
    const translate = useTranslate();

    const handleClickList = () => {
        setView('list');
    };

    return (
        <LeaveCalendar
            headerToolbar={{
                left: 'prev,next today',
                center: 'title',
                right: 'listButton',
            }}
            selectable={false}
            eventClick={false}
            customButtons={{
                listButton: {
                    text: translate('List'),
                    click: handleClickList
                }
            }}
            height="100%"
            sx={calendarSx}
            {...props}
        />
    );
};

const NextLeavesCard = () => {
    const [view, setView] = useState('calendar');
    const { data } = useGetList(
        'leaves',
        {
            pagination: { page: 1, perPage: 4 },
            sort: { field: 'start_date', order: 'ASC' },
            filter: { status: 'accepted', start_date: NOW_DATE }
        }
    );
    const translate = useTranslate();

    if (data?.length > 0) {
        return (
            <Grid item lg={3} sm={6} xl={3} xs={12}>
                <IconCard
                    title={translate('Next leaves')}
                    icon={<CalendarMonthIcon />}
                    content={view === 'list' ? <NextLeaves data={data} setView={setView} /> : <Calendar initialDate={data[0].start_date} setView={setView} />}
                    sx={{ height: '100%', display: 'flex', flexDirection: 'column', minHeight: '380px' }}
                    linkTo={`/leaves?filter={"status":"accepted","start_date":"${NOW_DATE}"}&sort=start_date&order=ASC`}
                />
            </Grid>
        );
    }

    return null;
};

export default NextLeavesCard;