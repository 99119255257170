import React, { useState } from 'react';
import { Confirm, useRefresh, useNotify, useRecordContext, usePermissions } from 'react-admin';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import IconButton from '../../common/buttons/IconButton';

import { publish } from '../../../services/api';

const PublishButton = () => {
    const record = useRecordContext();
    const [loading, setLoading] = useState(false);
    const [showDialog, setShowDialog] = useState(false);
    const refresh = useRefresh();
    const notify = useNotify();
    const { permissions } = usePermissions();

    const handleOpen = () => {
        setShowDialog(true);
    };

    const handleClose = () => {
        setShowDialog(false);
    };

    const handleUnpublish = async () => {
        setLoading(true);
        const response = await publish(record.id);
        if (response.success) {
            notify('Article published');
            refresh();
        }
        setLoading(false);
        handleClose();
    };

    if (permissions?.includes('is_admin')) {
        return (
            <>
                <IconButton onClick={handleOpen} icon={<CheckCircleIcon />} disabled={loading}>Publish</IconButton>
                <Confirm
                    isOpen={showDialog}
                    title="Publish"
                    content={`Publish ${record?.title}?`}
                    onConfirm={handleUnpublish}
                    onClose={handleClose}
                />
            </>
        );
    };

    return null;
};

export default PublishButton;