import React from 'react';
import { useRecordContext, useTranslate } from 'react-admin';
import { orange, red, grey, blue, green } from '@mui/material/colors';
import get from 'lodash/get';

import ChipField from '../../../common/fields/ChipField';


const PriorityField = ({ source = 'priority', size = 'small', ...props }) => {
    const record = useRecordContext(props);
    const translate = useTranslate();

    if (!record) {
        return null;
    }
    const priority = get(record, source);
    let sx = {};
    switch (priority) {

        case 'Bas':
            sx = { backgroundColor: (theme) => theme.palette.mode === 'dark' ? blue[700] : blue[100] };
            break;
        case 'Normal':
            sx = { backgroundColor: (theme) => theme.palette.mode === 'dark' ? green[700] : green[100] };
            break;
        case 'Haut':
            sx = { backgroundColor: (theme) => theme.palette.mode === 'dark' ? orange[700] : orange[200] };
            break;
        case 'Urgent':
        case 'Très urgent':
            sx = { backgroundColor: (theme) => theme.palette.mode === 'dark' ? red[700] : red[200] };
            break;
        default:
            sx = { backgroundColor: (theme) => theme.palette.mode === 'dark' ? grey[700] : grey[200] };
            break;
    }

    if (!priority) {
        return null;
    }

    const priorities = {
        'Bas': translate('Low'),
        'Normal': translate('Normal'),
        'Haut': translate('High'),
        'Urgent': translate('Urgent'),
        'Très urgent': translate('Very urgent')
    };

    return (
        <ChipField {...props} size={size} source={priority} record={priorities} sx={sx} />
    );
};

export default PriorityField;
