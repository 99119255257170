import React from 'react';
import { TextField, useGetList, useTranslate } from 'react-admin';
import { Link } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import LightbulbIcon from '@mui/icons-material/Lightbulb';

import IconCard from '../common/IconCard';
import VoteField from 'components/suggestions/fields/VoteField';

const voteSx = {
    margin: 0,
    fontSize: 14,
    minWidth: 'auto',
    '& span': { margin: 0, display: 'flex', alignItems: 'center' },
    '& svg': { fontSize: '14px !important' }
};

const NewSuggestions = ({ data }) => {
    if (data?.length > 0) {
        return (
            <List dense sx={{ padding: 0 }}>
                {data.map(record => (
                    <ListItemButton component={Link} to={`/suggestions/${record.id}/show`} key={`suggestions_${record.id}`}>
                        <ListItemText
                            primary={<TextField source="title" record={record} />}
                            secondary={record?.user?.fullname}
                            sx={{ maxWidth: '70%' }}
                        />
                        <ListItemSecondaryAction>
                            <VoteField record={record} sx={voteSx} disabled />
                        </ListItemSecondaryAction>
                    </ListItemButton>
                ))}
            </List>
        );
    }

    return null;
};

const NewSuggestionsCard = () => {
    const { data, total } = useGetList(
        'suggestions',
        {
            pagination: { page: 1, perPage: 3 },
            sort: { field: 'id', order: 'DESC' },
            filter: { status: 'new' }
        }
    );
    const translate = useTranslate();

    if (data?.length > 0) {
        return (
            <Grid item lg={3} sm={6} xl={3} xs={12}>
                <IconCard
                    title={translate('New suggestions')}
                    icon={<LightbulbIcon />}
                    value={total}
                    content={<NewSuggestions data={data} />}
                    sx={{ height: '100%' }}
                    linkTo={`/suggestions?filter={"status":"new"}`}
                />
            </Grid>
        );
    }

    return null;
};

export default NewSuggestionsCard;