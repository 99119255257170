import React from 'react';
import { SimpleShowLayout, TextField, RichTextField, useRecordContext, useTranslate, usePermissions } from 'react-admin';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import Show, { ShowActions } from '../common/views/Show';
import DateField from '../common/fields/DateField';
import CategoryField from '../articles/fields/CategoryField';
import PublishButton from './buttons/PublishButton';
import UnpublishButton from './buttons/UnpublishButton';

const introSx = { fontSize: 20, whiteSpace: 'pre-wrap' };
const contentSx = { fontSize: 16 };
const subtitleSx = {
	mb: 4,
	mt: 1,
	'& span': {
		fontSize: 13,
		lineHeight: '16px'
	}
};

const ArticleActions = () => {
	const record = useRecordContext();
	const { permissions } = usePermissions();

	return (
		<ShowActions>
			{permissions?.includes('is_admin') && (
				<>
					{record?.published ? (
						<UnpublishButton />
					) : (
						<PublishButton />
					)}
				</>
			)}
		</ShowActions>
	);
};

const Content = () => {
	const record = useRecordContext();
	const translate = useTranslate();

	return (
		<Box display="flex" flexDirection="column" p={2}>
			<Box mb={1}>
				<CategoryField sx={{ textTransform: 'uppercase', fontSize: 11 }} />
			</Box>
			<Typography variant="h1" sx={{ fontSize: 30 }}>{record.title}</Typography>
			<Box display="flex" sx={subtitleSx}>
				<span>
					<DateField source="published_at" />
					{record?.user?.fullname && (
						<>
							{record.published_at ? `, ` : ''}{translate('by')} {record?.user?.fullname}
						</>
					)}
				</span>
			</Box>

			<Box sx={{ maxWidth: 675 }}>
				<TextField source="intro" options={{ multiline: true }} sx={introSx} />
				<RichTextField source="content" sx={contentSx} className="rich-text" />
			</Box>
		</Box>
	);
};

const ArticleShow = props => (
	<Show {...props} actions={<ArticleActions />}>
		<SimpleShowLayout>
			<Content label={false} />
		</SimpleShowLayout>
	</Show>
);

export default ArticleShow;
