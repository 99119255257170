import React from 'react';
import { SimpleForm, TextInput, required } from 'react-admin';

import Create from '../common/views/Create';
import RichTextInput, { FullRichTextInputToolbar } from '../common/inputs/RichTextInput';
import CategoryInput from './inputs/CategoryInput';


const ArticleCreate = props => (
	<Create {...props}>
		<SimpleForm redirect="list">
			<CategoryInput validate={[required()]} />
			<TextInput source="title" validate={[required()]} fullWidth />
			<TextInput source="intro" multiline rows="4" fullWidth />
			<RichTextInput source="content" toolbar={<FullRichTextInputToolbar />} />
		</SimpleForm>
	</Create>
);

export default ArticleCreate;
