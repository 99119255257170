import React from 'react';
import { SearchInput, TextField, ChipField, Link, useRecordContext } from 'react-admin';
import Typography from '@mui/material/Typography';
import { green, orange, red } from '@mui/material/colors';
import { useTheme } from '@mui/material/styles';

import List from '../../common/views/List';
import Datagrid from '../../common/Datagrid';
import ResourceLinkField from '../../common/fields/ResourceLinkField';
import WorkloadField from '../../weeklies/fields/WorkloadField';
import RatingField from '../../common/fields/RatingField';

import { getActiveProjectsSeverity, getOccupancyRateSeverity, getWorkloadSeverity } from './teamStatusUtils';

const rowSx = (record) => {
    let sx = {
        borderLeftColor: 'transparent',
        borderLeftWidth: 5,
        borderLeftStyle: 'solid',
    };

    const items = [
        getActiveProjectsSeverity(record?.active_projects_count) || 3,
        getWorkloadSeverity(record?.last_weekly?.workload) || 3,
        getOccupancyRateSeverity(record?.last_weekly?.occupancy_rate) || 3
    ];
    const severity = Math.ceil(items.reduce((acc, value) => acc + value) / items.length);

    switch (severity) {
        case 1:
            sx = { ...sx, borderLeftColor: theme => theme.palette.mode === 'dark' ? green[700] : green[200] };
            break;
        case 2:
            sx = { ...sx, borderLeftColor: theme => theme.palette.mode === 'dark' ? orange[700] : orange[200] };
            break;
        case 3:
            sx = { ...sx, borderLeftColor: theme => theme.palette.mode === 'dark' ? red[700] : red[200] };
            break;
        default:
            break;

    }
    return sx;
};

const userFilters = [
    <SearchInput source="q" alwaysOn />
];

const FullNameField = ({ record }) => (
    <Typography variant="body2">{record.firstname} {record.lastname}</Typography>
);

const ActiveProjectsField = () => {
    const record = useRecordContext();
    const theme = useTheme();
    const { active_projects_count } = record;
    const severity = getActiveProjectsSeverity(active_projects_count);

    let sx = {};
    if (severity === 3) {
        sx = { backgroundColor: theme.palette.mode === 'dark' ? red[700] : red[100] };
    }
    else if (severity === 2) {
        sx = { backgroundColor: theme.palette.mode === 'dark' ? orange[700] : orange[100] };
    }
    else if (severity === 1) {
        sx = { backgroundColor: theme.palette.mode === 'dark' ? green[700] : green[100] };
    }

    if (active_projects_count) {
        return (
            <Link to={`/projects?filter={"status":"active","user_id":${record.id}}`}>
                <ChipField record={record} source="active_projects_count" size="small" sx={sx} />
            </Link>
        );
    }

    return null;
};

const TeamStatusList = (props) => (
    <List
        resource='users'
        sort={{ field: 'id', order: 'ASC' }}
        filter={{ active: true }}
        perPage={25}
        filters={userFilters}
        {...props}
    >
        <Datagrid rowSx={rowSx} bulkActionButtons={false}>
            <ResourceLinkField label="Name" resource="users">
                <FullNameField />
            </ResourceLinkField>
            <TextField source="trigram" label="" />
            <TextField source="status" />
            <ActiveProjectsField label="Active projects" />
            <RatingField source="last_weekly.occupancy_rate" label="Occupancy rate" />
            <WorkloadField source="last_weekly.workload" label="Workload" />
        </Datagrid>
    </List>
);

export default TeamStatusList;