import React from 'react';
import { TextField, useRecordContext, usePermissions } from 'react-admin';
import moment from 'moment';

import Datagrid from '../../common/Datagrid';
import RecordSplitButton from '../../common/buttons/RecordSplitButton';
import ResourceLinkField from '../../common/fields/ResourceLinkField';
import DateField from '../../common/fields/DateField';

const NOW = moment();

const SplitButton = (props) => {
    const record = useRecordContext();
    const { permissions } = usePermissions();
    const hasPermission = permissions?.includes('is_admin') && NOW.diff(moment(record?.date), 'days') <= 35;

    return (
        <RecordSplitButton
            {...props}
            hasEdit={hasPermission}
            hasDelete={hasPermission}
        />
    );
};

const MonthlyRow = props => (
    <Datagrid {...props}>
        <ResourceLinkField label="Id" resource="monthlies" sortBy="id">
            <TextField source="id" />
        </ResourceLinkField>
        <DateField source="date" />
        <SplitButton />
    </Datagrid>
);

export default MonthlyRow;