import React from 'react';
import { SimpleForm, TextInput, ReferenceInput, AutocompleteInput, required } from 'react-admin';

import Edit from '../common/views/Edit';
import CategoryInput from './inputs/CategoryInput';
import RichTextInput, { FullRichTextInputToolbar } from '../common/inputs/RichTextInput';

const userOptionText = choice => choice?.fullname ? choice.fullname : '';

const ArticleEdit = props => (
	<Edit {...props}>
		<SimpleForm redirect="list">
			<ReferenceInput label="User" source="user_id" reference="users" sort={{ field: 'id', order: 'ASC' }}>
				<AutocompleteInput optionText={userOptionText} size="small" />
			</ReferenceInput>
			<CategoryInput validate={[required()]} />
			<TextInput source="title" validate={[required()]} fullWidth />
			<TextInput source="intro" multiline rows="4" fullWidth />
			<RichTextInput source="content" toolbar={<FullRichTextInputToolbar />} />
		</SimpleForm>
	</Edit>
);

export default ArticleEdit;
