import React from 'react';
import { useRecordContext } from 'react-admin';
import Box from '@mui/material/Box';
import MessageIcon from '@mui/icons-material/Message';
import { grey } from '@mui/material/colors';

const CommentCountField = () => {
    const record = useRecordContext();
    const commentCount = record?.comments?.length;

    if (!commentCount) {
        return null;
    }

    return (
        <Box display="flex" sx={{ color: grey[400] }}>
            <MessageIcon fontSize="small" sx={{ mr: 1 }} />
            {commentCount || 0}
        </Box>
    );
};

export default CommentCountField;