import React, { useEffect, useState } from 'react';
import { Link, useTranslate, useGetIdentity } from 'react-admin';
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import moment from 'moment';
import { green, red, orange, blue } from '@mui/material/colors';

import TimeEntryBarChart from '../time_entries/charts/TimeEntryBarChart';

const NOW = moment();
const MAX_HOURS_DAY = 8;
const PIE_HEIGHT = 120;

const getColor = (value) => {
    if (value >= 90) {
        return green[200];
    }
    else if (value >= 80) {
        return blue[200];
    }
    else if (value >= 60) {
        return orange[200];
    }

    return red[200];
};

const TimeEntryBarChartCard = () => {
    const [data, setData] = useState();
    const [total, setTotal] = useState();
    const [rate, setRate] = useState();
    const [maxHours, setMaxHours] = useState();
    const { data: account } = useGetIdentity();
    const translate = useTranslate();

    const nowDate = NOW.format('YYYY-MM-DD');
    const lastWeekDate = moment(NOW).subtract(6, 'days').format('YYYY-MM-DD');

    useEffect(() => {
        let dates = [];
        const workingDays = data?.reduce((acc, item) => {
            const date = new Date(item.date);
            if (!dates.includes(item.date) && ![0, 6].includes(date.getDay())) {
                dates.push(item.date);
                return acc += 1;
            }
            dates.push(item.date);
            return acc;
        }, 0);

        const maxHours = workingDays * MAX_HOURS_DAY;
        setMaxHours(maxHours);
        setRate((total / maxHours) * 100);
    }, [data, total]);

    if (!account?.id) {
        return null;
    }

    return (
        <Link to={`/time_entries/stats?filter={"user_id":${account?.id},"group_by":"project","freq":"daily"}`} sx={{ textDecoration: 'none' }}>
            <Card sx={{ height: '100%', padding: '12px' }}>
                <Typography variant="h6">{translate('Last 7 days timesheet')}</Typography>
                <Box sx={{ width: '100%', height: '160px', marginTop: '15px' }}>
                    <TimeEntryBarChart
                        startDate={lastWeekDate}
                        endDate={nowDate}
                        userId={account?.id}
                        groupBy="project"
                        showLegend={false}
                        showTotal={false}
                        freq="daily"
                        referenceLineLabel=" "
                        setData={setData}
                        setTotal={setTotal}
                        margin={{ left: -40 }}
                    />
                </Box>
                <Box>
                    <Grid container spacing={2} sx={{ marginTop: '10px' }}>
                        <Grid item lg={7} md={12} sm={12} xs={12}>
                            <Box marginBottom={`-${PIE_HEIGHT/2}px`}>
                                <ResponsiveContainer width="100%" height={PIE_HEIGHT}>
                                    <PieChart>
                                        <Pie
                                            data={[{ value: rate < 100 ? rate : 100 }, { value: rate < 100 ? 100 - rate : 0 }]}
                                            startAngle={180}
                                            endAngle={0}
                                            innerRadius="70%"
                                            outerRadius="100%"
                                            fill="#8884d8"
                                            dataKey="value"
                                        >
                                            <Cell fill={getColor(rate)} />
                                            <Cell fill={rate === 0 ? red[200] : '#CECECE'} />
                                        </Pie>
                                    </PieChart>
                                </ResponsiveContainer>
                            </Box>
                        </Grid>
                        <Grid item lg={5} md={12} sm={12} xs={12}>
                            <Box sx={{ margin: '15px 0' }}>
                                <Typography>{translate('Rate')}: {rate?.toFixed(2) || 0}%</Typography>
                                <Typography>{translate('Total')}: {total}{maxHours ? `/${maxHours}` : ''}h</Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Card>
        </Link>
    );
};

export default TimeEntryBarChartCard;