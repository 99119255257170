import React, { useState } from 'react';
import { Confirm, Button, useRecordContext, useNotify, useRefresh, usePermissions } from 'react-admin';
import CloseIcon from '@mui/icons-material/Close';

import { markRefusedSuggestion } from '../../../services/api';

const RefuseButton = () => {
    const [loading, setLoading] = useState(false);
    const [showDialog, setShowDialog] = useState(false);
    const record = useRecordContext();
    const { permissions } = usePermissions();
    const notify = useNotify();
    const refresh = useRefresh();

    const handleOpen = () => {
        setShowDialog(true);
    };

    const handleClose = () => {
        setShowDialog(false);
    };

    const handleRefuse = async () => {
        setLoading(true);
        const response = await markRefusedSuggestion(record.id);

        if (response.success) {
            notify(`Suggestion refused`);
            refresh();
        } else if (response.reason) {
            notify(response.reason, 'warning');
        }
        setLoading(false);
    };

    if (!permissions.includes('is_admin') || record?.status !== 'new') {
        return null;
    }

    return (
        <>
            <Button label="Refuse" onClick={handleOpen} disabled={loading} color="error">
                <CloseIcon />
            </Button>
            <Confirm
                isOpen={showDialog}
                title="Refuse"
                content="Refuse the suggestion?"
                onConfirm={handleRefuse}
                onClose={handleClose}
            />
        </>
    );
};

export default RefuseButton;