import React from 'react';
import { usePermissions, useRecordContext, useGetIdentity } from 'react-admin';
import Grid from '@mui/material/Grid';
import moment from 'moment';

import { ShowBase, ShowActions } from '../common/views/Show';
import WeeklyCard from './WeeklyCard';

const NOW = moment();

const WeeklyShowActions = (props) => {
    const record = useRecordContext();
    const { data: account } = useGetIdentity();
    const { permissions } = usePermissions();
    const hasPermission = permissions?.includes('is_admin') || (NOW.diff(moment(record?.date), 'days') <= 14 && record?.user_id === account?.id);

    return (
        <ShowActions
            {...props}
            hasEdit={hasPermission}
        />
    );
};

const Content = () => {
    const record = useRecordContext();

    return (
        <WeeklyCard record={record} actions={false} showWorkloadColor={false} />
    )
};

const WeeklyShow = () => (
    <ShowBase>
        <WeeklyShowActions />
        <Grid container spacing={2} sx={{ marginBottom: '30px' }}>
            <Grid item xs={12}>
                <Content />
            </Grid>
        </Grid>
    </ShowBase>
);

export default WeeklyShow;