import React, { useState } from 'react';
import { Confirm, useRefresh, useNotify, useRecordContext, usePermissions } from 'react-admin';
import UnpublishedIcon from '@mui/icons-material/Unpublished';

import IconButton from '../../common/buttons/IconButton';

import { unpublish } from '../../../services/api';

const UnpublishButton = () => {
    const record = useRecordContext();
    const [loading, setLoading] = useState(false);
    const [showDialog, setShowDialog] = useState(false);
    const refresh = useRefresh();
    const notify = useNotify();
    const { permissions } = usePermissions();

    const handleOpen = () => {
        setShowDialog(true);
    };

    const handleClose = () => {
        setShowDialog(false);
    };

    const handleUnpublish = async () => {
        setLoading(true);
        const response = await unpublish(record.id);
        if (response.success) {
            notify('Article unpublished');
            refresh();
        }
        setLoading(false);
        handleClose();
    };

    if (permissions?.includes('is_admin')) {
        return (
            <>
                <IconButton onClick={handleOpen} icon={<UnpublishedIcon />} disabled={loading}>Unpublish</IconButton>
                <Confirm
                    isOpen={showDialog}
                    title="Unpublish"
                    content={`Unpublish ${record?.title}?`}
                    onConfirm={handleUnpublish}
                    onClose={handleClose}
                />
            </>
        );
    }

    return null;
};

export default UnpublishButton;