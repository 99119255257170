import React from 'react';
import { useRecordContext, useTranslate } from 'react-admin';
import { green, orange, red, grey, blue } from '@mui/material/colors';

import ChipField from '../../common/fields/ChipField';


const StatusField = ({ source = 'status', size = 'small', ...props }) => {
    const record = useRecordContext(props);
    const translate = useTranslate();

    if (!record) {
        return null;
    }
    const status = record[source];
    let sx = {};
    switch (status) {
        case 'active':
        case 'done':
            sx = { backgroundColor: (theme) => theme.palette.mode === 'dark' ? green[700] : green[200] };
            break;
        case 'paused':
            sx = { backgroundColor: (theme) => theme.palette.mode === 'dark' ? orange[700] : orange[200] };
            break;
        case 'maintenance':
            sx = { backgroundColor: (theme) => theme.palette.mode === 'dark' ? blue[700] : blue[100] };
            break;
        case 'cancelled':
        case 'late':
        case 'blocked':
            sx = { backgroundColor: (theme) => theme.palette.mode === 'dark' ? red[700] : red[200] };
            break;
        default:
            sx = { backgroundColor: (theme) => theme.palette.mode === 'dark' ? grey[700] : grey[200] };
            break;
    }

    if (!status) {
        return null;
    }

    const statuses = {
        'active': translate('Active'),
        'paused': translate('Paused'),
        'maintenance': translate('Maintenance'),
        'done': translate('Done'),
        'cancelled': translate('Cancelled'),
        'late': translate('Late'),
        'blocked': translate('Blocked'),
    };

    return (
        <ChipField {...props} size={size} source={status} record={statuses} sx={sx} />
    );
};

export default StatusField;
