import React, { useState } from 'react';
import { Confirm, Button, useRecordContext, useNotify, useRefresh, usePermissions } from 'react-admin';
import CheckIcon from '@mui/icons-material/Check';

import { markAcceptedSuggestion } from '../../../services/api';

const AcceptButton = () => {
    const [loading, setLoading] = useState(false);
    const [showDialog, setShowDialog] = useState(false);
    const record = useRecordContext();
    const { permissions } = usePermissions();
    const notify = useNotify();
    const refresh = useRefresh();

    const handleOpen = () => {
        setShowDialog(true);
    };

    const handleClose = () => {
        setShowDialog(false);
    };

    const handleAccept = async () => {
        setLoading(true);
        const response = await markAcceptedSuggestion(record.id);

        if (response.success) {
            notify(`Suggestion accepted`);
            refresh();
        } else if (response.reason) {
            notify(response.reason, 'warning');
        }
        setLoading(false);
    };

    if (!permissions.includes('is_admin') || record?.status !== 'new') {
        return null;
    }

    return (
        <>
            <Button label="Accept" onClick={handleOpen} disabled={loading} color="success">
                <CheckIcon />
            </Button>
            <Confirm
                isOpen={showDialog}
                title="Accept"
                content="Accept the suggestion?"
                onConfirm={handleAccept}
                onClose={handleClose}
            />
        </>
    );
};

export default AcceptButton;