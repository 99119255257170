import NewspaperIcon from '@mui/icons-material/Newspaper';

import ArticleList from './ArticleList';
import ArticleShow from './ArticleShow';
import ArticleCreate from './ArticleCreate';
import ArticleEdit from './ArticleEdit';

const articles = {
	list: ArticleList,
	show: ArticleShow,
	create: ArticleCreate,
	edit: ArticleEdit,
	icon: NewspaperIcon
};

export default articles;
