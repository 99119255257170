import React from 'react';
import { Datagrid, TextField, SearchInput, NullableBooleanInput, BooleanField, usePermissions } from 'react-admin';

import List from '../common/views/List';
import ResourceLinkField from '../common/fields/ResourceLinkField';
import HumanDateField from '../common/fields/HumanDateField';
import RecordSplitButton from '../common/buttons/RecordSplitButton';

const adminFilters = [
	<SearchInput source="q" alwaysOn />,
	<NullableBooleanInput source="published" alwaysOn />
];

const filters = [
	<SearchInput source="q" alwaysOn />
];

const NewsList = props => {
	const { permissions } = usePermissions();
	const hasPermission = permissions?.includes('is_admin');

	return (
		<List
			filter={{ category: 'news' }}
			filters={permissions?.includes('is_admin') ? adminFilters : filters}
			exporter={false}
			{...props}
		>
			<Datagrid>
				<ResourceLinkField label="Id" resource="articles" sortBy="id">
					<TextField source="id" />
				</ResourceLinkField>
				<TextField source="title" />
				{permissions?.includes('is_admin') && (<BooleanField source="published" />)}
				<ResourceLinkField label="User" source="user" resource="users" sortBy="user_id">
					<TextField source="fullname" />
				</ResourceLinkField>
				<HumanDateField source="published_at" />
				<RecordSplitButton hasEdit={hasPermission} hasDelete={hasPermission} />
			</Datagrid>
		</List>
	);
};

export default NewsList;
