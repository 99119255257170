import React, { useState } from 'react';
import { Link, DateField, useGetList, useTranslate } from 'react-admin';
import Grid from '@mui/material/Grid';
import MobileStepper from '@mui/material/MobileStepper';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import CardActionArea from '@mui/material/CardActionArea';
import Box from '@mui/material/Box';

import CategoryField from '../articles/fields/CategoryField';

const subtitleSx = {
    mb: 2,
    '& span': {
        fontSize: 13,
        lineHeight: '16px'
    }
};

const ArticleCard = ({ record }) => {
    let { intro } = record;
    if (intro?.length > 350) {
        intro = intro?.slice(0, 350).concat('...');
    }
    const translate = useTranslate();

    return (
        <Link to={`/news/${record.id}/show`} sx={{ textDecoration: 'none', flex: 1 }}>
            <CardActionArea sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                <CardContent sx={{ width: '100%', marginBottom: 'auto' }}>
                    <CategoryField record={record} sx={{ mb: 0.5 }} />
                    <Typography gutterBottom variant="h5" component="div">{record.title}</Typography>
                    {record?.published_at && (
                        <Box display="flex" sx={subtitleSx}>
                            <span>
                                <DateField source="published_at" record={record} />
                                {record?.user?.fullname && `, ${translate('by')} ${record?.user?.fullname}`}
                            </span>
                        </Box>
                    )}
                    <Typography variant="body2" sx={{ color: 'text.secondary', whiteSpace: 'pre-wrap' }}>
                        {intro}
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Link>
    );
};

const NewsList = ({ data }) => {
    const [activeStep, setActiveStep] = useState(0);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const steps = data.map(record => (
        <ArticleCard record={record} />
    ))
    const maxSteps = steps.length;

    if (data?.length > 0 && steps?.length > 0) {
        return (
            <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                {steps[activeStep]}
                <MobileStepper
                    variant="text"
                    steps={maxSteps}
                    position="static"
                    activeStep={activeStep}
                    nextButton={
                        <Button
                            size="small"
                            onClick={handleNext}
                            disabled={activeStep === maxSteps - 1}
                        >
                            <KeyboardArrowRight />
                        </Button>
                    }
                    backButton={
                        <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                            <KeyboardArrowLeft />
                        </Button>
                    }
                />
            </Card>
        );
    }
    return null;
};

const LastNewsCard = () => {
    const { data } = useGetList(
        'articles',
        {
            pagination: { page: 1, perPage: 3 },
            sort: { field: 'published_at', order: 'ASC' },
            filter: { category: 'news', published: true }
        }
    );

    if (data?.length > 0) {
        return (
            <Grid item lg={3} sm={6} xl={3} xs={12}>
                <NewsList data={data} />
            </Grid>
        );
    }

    return null;
};

export default LastNewsCard;