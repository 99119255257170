import { useInput, useTranslateLabel, useTranslate } from 'react-admin';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Rating from '@mui/material/Rating';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import HourglassFullIcon from '@mui/icons-material/HourglassFull';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';

import variables from '../../../style/variables';

const StyledRating = styled(Rating)({
    '& .MuiRating-iconFilled': {
        color: variables.colors.primary,
    },
    '& .MuiRating-iconHover': {
        color: variables.colors.primary,
    },
});

const RatingInput = ({ helperText, ...props }) => {
    const { label, resource, source } = props;
    const { field } = useInput(props);
    const translateLabel = useTranslateLabel();
    const translate = useTranslate();

    return (
        <Box mb={2}>
            <Box display="flex" mb={1}>
                <Typography variant="body1" sx={{ fontSize: '0.75em', color: 'rgba(0, 0, 0, 0.6)' }}>{translateLabel({ label, resource, source })}</Typography>
                {helperText && (
                    <Box ml={1} sx={{ cursor: 'pointer' }}>
                        <Tooltip title={translate(helperText)}>
                            <HelpOutlineIcon color="disabled" fontSize="small" />
                        </Tooltip>
                    </Box>
                )}
            </Box>
            <StyledRating
                {...field}
                icon={<HourglassFullIcon fontSize="inherit" />}
                emptyIcon={<HourglassEmptyIcon fontSize="inherit" />}
                size="large"
                onChange={(e, value) => field.onChange(value)}
                {...props}
            />
        </Box>
    );
};

export default RatingInput;