import React from 'react';
import { Datagrid, TextField, SearchInput, NullableBooleanInput, BooleanField, usePermissions } from 'react-admin';

import List from '../common/views/List';
import ResourceLinkField from '../common/fields/ResourceLinkField';
import HumanDateField from '../common/fields/HumanDateField';
import RecordSplitButton from '../common/buttons/RecordSplitButton';
import CategoryInput from './inputs/CategoryInput';
import CategoryField from './fields/CategoryField';

const filters = [
	<SearchInput source="q" alwaysOn />,
	<CategoryInput source="category" alwaysOn />,
	<NullableBooleanInput source="published" alwaysOn />
];

const ArticleList = props => {
	const { permissions } = usePermissions();
	const hasPermission = permissions?.includes('is_admin');

	return (
		<List {...props} filters={filters} exporter={false}>
			<Datagrid>
				<ResourceLinkField label="Id" resource="articles" sortBy="id">
					<TextField source="id" />
				</ResourceLinkField>
				<TextField source="title" />
				<CategoryField source="category" />
				{permissions?.includes('is_admin') && (<BooleanField source="published" />)}
				<ResourceLinkField label="User" source="user" resource="users" sortBy="user_id">
					<TextField source="fullname" />
				</ResourceLinkField>
				<HumanDateField source="published_at" />
				<RecordSplitButton hasEdit={hasPermission} hasDelete={hasPermission} />
			</Datagrid>
		</List>
	);
};

export default ArticleList;
