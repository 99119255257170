import React from 'react';
import { TextInput, ReferenceInput, AutocompleteInput, DateInput, usePermissions } from 'react-admin';

import Edit from '../common/views/Edit';
import SimpleForm from '../common/forms/SimpleForm';
import RichTextInput from '../common/inputs/RichTextInput';
import WorkloadInput from './inputs/WorkloadInput';
import RatingInput from '../common/inputs/RatingInput';

const userOptionText = choice => choice?.fullname ? choice.fullname : '';


const WeeklyEdit = (props) => {
    const { permissions } = usePermissions();

    return (

        <Edit {...props}>
            <SimpleForm>
                {permissions?.includes('is_admin') && (
                    <ReferenceInput source="user_id" reference="users" sort={{ field: 'id', order: 'ASC' }}>
                        <AutocompleteInput optionText={userOptionText} size="small" />
                    </ReferenceInput>
                )}
                <DateInput source="date" />
                <WorkloadInput source="workload" />
                <RatingInput source="occupancy_rate" helperText="Estimate the number of days you are busy during the week" />
                <RichTextInput source="priorities" />
                <RichTextInput source="tasks" />
                <TextInput source="notes" multiline fullWidth rows="4" sx={{ '&&': { minWidth: '100%!important' } }} />
            </SimpleForm>
        </Edit>
    );
};

export default WeeklyEdit;
