import React, { useState } from 'react';
import { useGetList, useTranslate, useGetIdentity } from 'react-admin';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MobileStepper from '@mui/material/MobileStepper';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import MUILink from '@mui/material/Link';
import { orange, red } from '@mui/material/colors';

import IconCard from '../common/IconCard';
import HumanDateField from '../common/fields/HumanDateField';
import RedmineIcon from '../common/icons/Redmine';

const CHUNK_SIZE = 3;

const getPriorityBoxSx = ({ priority }) => {
    let sx = { width: '4px', height: '100%', position: 'absolute', left: 0 };
    switch (priority?.name) {
        case 'Haut':
            sx = { ...sx, backgroundColor: (theme) => theme.palette.mode === 'dark' ? orange[700] : orange[200] };
            break;
        case 'Urgent':
        case 'Très urgent':
            sx = { ...sx, backgroundColor: (theme) => theme.palette.mode === 'dark' ? red[700] : red[200] };
            break;
        default:
            break;
    }

    return sx;
};

const IssueList = ({ data }) => {
    const [activeStep, setActiveStep] = useState(0);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const renderListItem = (record) => (
        <ListItemButton component={MUILink} href={`https://redmine.kern-it.be/issues/${record.id}`} target="_blank" rel="noreferrer noopener" key={`issue_${record.id}`}>
            <Box sx={getPriorityBoxSx(record)} />
            <ListItemText
                primary={record.subject}
                secondary={<>{record.project.name} - <HumanDateField source="created_on" record={record} /></>}
            />
        </ListItemButton>
    );

    let steps = [];
    for (let i = 0; i < data?.length; i += CHUNK_SIZE) {
        let step = [];
        const chunk = data.slice(i, i + CHUNK_SIZE);
        chunk.forEach(record => {
            step.push(renderListItem(record));
        })
        steps.push(<List dense sx={{ padding: 0 }} key={`issue_list_${i}`}>{step}</List>);
    }
    const maxSteps = steps.length;

    if (data?.length > 0 && steps?.length > 0) {
        return (
            <>
                {steps[activeStep]}
                <MobileStepper
                    variant="text"
                    steps={maxSteps}
                    position="static"
                    activeStep={activeStep}
                    sx={{ marginTop: 'auto' }}
                    nextButton={
                        <Button
                            size="small"
                            onClick={handleNext}
                            disabled={activeStep === maxSteps - 1}
                        >
                            <KeyboardArrowRight />
                        </Button>
                    }
                    backButton={
                        <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                            <KeyboardArrowLeft />
                        </Button>
                    }
                />
            </>
        );
    }
    return null;
};

const MyLastIssuesCard = () => {
    const { data: account } = useGetIdentity();
    const translate = useTranslate();
    const { data, total } = useGetList(
        'redmine/issues',
        {
            pagination: { page: 1, perPage: 15 },
            sort: { field: 'id', order: 'DESC' },
            filter: { status: 'open', user_id: account?.id }
        }
    );

    if (data?.length > 0) {
        return (
            <Grid item lg={3} sm={6} xl={3} xs={12}>
                <IconCard
                    title={translate('Last Redmine tickets')}
                    value={total}
                    icon={<RedmineIcon />}
                    content={<IssueList data={data} />}
                    sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}
                />
            </Grid>
        );
    }

    return null;
};

export default MyLastIssuesCard;
