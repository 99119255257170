import NewspaperIcon from '@mui/icons-material/Newspaper';

import NewsList from './NewsList';
import NewsShow from './NewsShow';
import NewsCreate from './NewsCreate';
import NewsEdit from './NewsEdit';

const news = {
	list: NewsList,
	show: NewsShow,
	create: NewsCreate,
	edit: NewsEdit,
	icon: NewspaperIcon
};

export default news;
