// 1 = low, 2 = middle, 3 = high

export const getWorkloadSeverity = (value) => {
    let severity = 0;
    if (['normal'].includes(value)) {
        severity = 1;
    }
    else if (['high'].includes(value)) {
        severity = 2;
    }
    else if (['overloaded', 'low'].includes(value)) {
        severity = 3;
    }
    return severity;
};

export const getOccupancyRateSeverity = (value) => {
    let severity = 0;
    if (value > 3) {
        severity = 1;
    }
    else if (value === 3) {
        severity = 2;
    }
    else if (value < 3) {
        severity = 3;
    }
    return severity;
};

export const getActiveProjectsSeverity = (value) => {
    let severity = 0;
    if (value > 4 || value === 0) {
        severity = 3;
    }
    else if (value > 3) {
        severity = 2
    }
    else if (value >= 1) {
        severity = 1;
    }
    return severity;
};