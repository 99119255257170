import React from 'react';
import { Title } from 'react-admin';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const ReleaseNotes = () => (
    <Box my={5}>
        <Title title="Release notes" />
        <Card>
            <CardHeader title="Release notes" />
            <CardContent>
                <Accordion defaultExpanded>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}><Typography component="span">31/03/2025</Typography></AccordionSummary>
                    <AccordionDetails>
                        <ul>
                            <li>
                                Suggestions
                                <ul>
                                    <li>Deactivation of comments when the suggestion is no longer new</li>
                                    <li>Can update comments</li>
                                    <li>List
                                        <ul>
                                            <li>Add comment count on list</li>
                                            <li>Limit title column width</li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                Weeklies & monthlies
                                <ul>
                                    <li>Weeklies and monthlies are now operational</li>
                                    <li>Add date input</li>
                                    <li>Add occupancy rate</li>
                                    <li>Adapt list</li>
                                </ul>
                            </li>
                            <li>
                                Team status
                                <ul>
                                    <li>Add user status list</li>
                                    <li>Add status input to account page</li>
                                </ul>
                            </li>
                            <li>
                                News
                                <ul>
                                    <li>Add news</li>
                                </ul>
                            </li>
                            <li>
                                Dashboard
                                <ul>
                                    <li>Add last news card</li>
                                    <li>Add new suggestions card</li>
                                    <li>Add calendar with next leaves</li>
                                    <li>Weekly and monthly cards are back</li>
                                </ul>
                            </li>
                        </ul>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}><Typography component="span">21/02/2025</Typography></AccordionSummary>
                    <AccordionDetails>
                        <ul>
                            <li>
                                Dashboard
                                <ul>
                                    <li>Fix responsive for last 7 days timesheet pie chart</li>
                                    <li>Hide weekly and monthly temporarily</li>
                                    <li>Improve pending leaves card for admin</li>
                                </ul>
                            </li>
                            <li>
                                Ideas box
                                <ul>
                                    <li>Add accept and refuse buttons for admin</li>
                                </ul>
                            </li>
                        </ul>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}><Typography component="span">17/02/2025</Typography></AccordionSummary>
                    <AccordionDetails>
                        <ul>
                            <li>
                                Time entries
                                <ul>
                                    <li>Fix last time entry list which showed the filters of the general list on the create page</li>
                                </ul>
                            </li>
                        </ul>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}><Typography component="span">29/01/2025</Typography></AccordionSummary>
                    <AccordionDetails>
                        <ul>
                            <li>Add ideas box</li>
                            <li>
                                Leaves
                                <ul>
                                    <li>Send mail when leave is accepted or refused</li>
                                </ul>
                            </li>
                            <li>
                                Time entries
                                <ul>
                                    <li>Export improvements</li>
                                </ul>
                            </li>
                        </ul>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}><Typography component="span">24/01/2025</Typography></AccordionSummary>
                    <AccordionDetails>
                        <ul>
                            <li>
                                Leaves
                                <ul>
                                    <li>Send mail when pending leaves</li>
                                    <li>Hide pending leaves when empty on dashboard (for admin)</li>
                                </ul>
                            </li>
                            <li>
                                Time entries
                                <ul>
                                    <li>Replace export button</li>
                                </ul>
                            </li>
                        </ul>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}><Typography component="span">23/01/2025</Typography></AccordionSummary>
                    <AccordionDetails>
                        <ul>
                            <li>Fix account update when color empty</li>
                            <li>Hide next leaves card when empty on dashboard</li>
                            <li>Restrict custom pages to authenticated users</li>
                            <li>Add accordions to release notes to improve clarity</li>
                            <li>Fix reset password and forgot password inputs width</li>
                            <li>Update favicon</li>
                            <li>
                                Projects
                                <ul>
                                    <li>Fix project update, sometimes returned an error</li>
                                    <li>Display customer name instead of customer short name on project grid</li>
                                </ul>
                            </li>
                            <li>
                                Time entries
                                <ul>
                                    <li>Add tooltip with customer name to project field in time entry list</li>
                                    <li>Improve the way the customer is displayed on the project inputs</li>
                                    <li>Add project input with customer to timer page</li>
                                    <li>Add button to hide or show fields on time entry list</li>
                                    <li>Improve responsive on list</li>
                                    <li>Add help button to time entry edit</li>
                                </ul>
                            </li>
                            <li>
                                Customers
                                <ul>
                                    <li>Add customer details page</li>
                                </ul>
                            </li>
                        </ul>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}><Typography component="span">21/01/2025</Typography></AccordionSummary>
                    <AccordionDetails>
                        <ul>
                            <li>Increase auth token validity to one week</li>
                        </ul>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}><Typography component="span">17/01/2025</Typography></AccordionSummary>
                    <AccordionDetails>
                        <ul>
                            <li>Add build date</li>
                            <li>Add "Release notes" page</li>
                            <li>
                                Time entries
                                <ul>
                                    <li>Add filter "for me" on list</li>
                                    <li>Reorder fields on bulk create</li>
                                    <li>Default date is current date on bulk create</li>
                                    <li>Add "created at" on update</li>
                                    <li>Take project alias instead of name for CSV export</li>
                                    <li>Show customer on some project inputs</li>
                                    <li>Fix title on bulk create</li>
                                </ul>
                            </li>
                            <li>
                                Project
                                <ul>
                                    <li>Add alias to search</li>
                                </ul>
                            </li>
                            <li>
                                Users
                                <ul>
                                    <li>Fix stats endpoint</li>
                                </ul>
                            </li>
                            <li>
                                Useful links
                                <ul>
                                    <li>Add followup</li>
                                    <li>Remove leaves and timesheet</li>
                                </ul>
                            </li>
                        </ul>
                    </AccordionDetails>
                </Accordion>
            </CardContent>
        </Card>
    </Box>
);

export default ReleaseNotes;