import React from 'react';
import { List, TextField, SearchInput } from 'react-admin';

import Datagrid from '../common/Datagrid';
import RecordSplitButton from '../common/buttons/RecordSplitButton';
import ResourceLinkField from '../common/fields/ResourceLinkField';
import HumanDateField from '../common/fields/HumanDateField';
import VoteField from './fields/VoteField';
import StatusField from './fields/StatusField';
import CategoryField from './fields/CategoryField';
import CommentCountField from './fields/CommentCountField';
import CategoryInput from './inputs/CategoryInput';
import StatusInput from './inputs/StatusInput';

const suggestionFilters = [
    <SearchInput source="q" alwaysOn />,
    <CategoryInput source="category" alwaysOn />,
    <StatusInput source="status" alwaysOn />
];

const SuggestionList = props => (
    <List filters={suggestionFilters} {...props}>
        <Datagrid>
            <TextField source="id" />
            <TextField source="title" sx={{ display: 'block', maxWidth: 375 }} />
            <CategoryField source="category" />
            <ResourceLinkField label="User" source="user" resource="users" sortBy="user_id">
                <TextField source="fullname" />
            </ResourceLinkField>
            <StatusField source="status" />
            <HumanDateField source="created_at" />
            <CommentCountField />
            <VoteField />
            <RecordSplitButton />
        </Datagrid>
    </List>
);

export default SuggestionList;