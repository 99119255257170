import * as React from 'react';
import { DashboardMenuItem, Menu as RAMenu, MenuItemLink, Link, useGetResourceLabel, usePermissions } from 'react-admin';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import InfoIcon from '@mui/icons-material/Info';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import moment from 'moment';
import preval from 'preval.macro';

import users from './users';
import customers from './customers';
import projects from './projects';
import weeklies from './weeklies';
import monthlies from './monthlies';
import leaves from './leaves';
import time_entries from './time_entries';
import time_entry_types from './time_entry_types';
import suggestions from './suggestions';
import articles from './articles';


const Menu = ({ onMenuClick, ...props }) => {
	const getResourceLabel = useGetResourceLabel();
	const { permissions } = usePermissions();

	const getBuildDate = () => {
		const buildDate = moment(preval`module.exports = new Date();`);
		const now = moment.now();
		return moment.duration(buildDate - now).humanize(true);
	};

	return (
		<RAMenu {...props}>
			<DashboardMenuItem />
			<MenuItemLink
				to={`/time_entries`}
				primaryText="Timesheet"
				leftIcon={<time_entries.icon />}
				onClick={onMenuClick}
			/>
			{permissions?.includes('is_admin') && (
				<MenuItemLink
					to={`/time_entry_types`}
					primaryText={getResourceLabel('time_entry_types', 2)}
					leftIcon={<time_entry_types.icon />}
					onClick={onMenuClick}
				/>
			)}
			<MenuItemLink
				to={`/customers`}
				primaryText={getResourceLabel('customers', 2)}
				leftIcon={<customers.icon />}
				onClick={onMenuClick}
			/>
			<MenuItemLink
				to={`/projects`}
				primaryText={getResourceLabel('projects', 2)}
				leftIcon={<projects.icon />}
				onClick={onMenuClick}
			/>
			<MenuItemLink
				to={`/weeklies`}
				primaryText={getResourceLabel('weeklies', 2)}
				leftIcon={<weeklies.icon />}
				onClick={onMenuClick}
			/>
			<MenuItemLink
				to={`/monthlies`}
				primaryText={getResourceLabel('monthlies', 2)}
				leftIcon={<monthlies.icon />}
				onClick={onMenuClick}
			/>
			<MenuItemLink
				to={`/leaves`}
				primaryText={getResourceLabel('leaves', 2)}
				leftIcon={<leaves.icon />}
				onClick={onMenuClick}
			/>
			<MenuItemLink
				to={`/news`}
				primaryText="News"
				leftIcon={<articles.icon />}
				onClick={onMenuClick}
			/>
			<MenuItemLink
				to={`/suggestions`}
				primaryText="Ideas box"
				leftIcon={<suggestions.icon />}
				onClick={onMenuClick}
			/>
			<MenuItemLink
				to={`/team-status`}
				primaryText="Team status"
				leftIcon={<FactCheckIcon />}
				onClick={onMenuClick}
			/>
			<MenuItemLink
				to={`/users`}
				primaryText={getResourceLabel('users', 2)}
				leftIcon={<users.icon />}
				onClick={onMenuClick}
			/>
			<Divider />
			<MenuItemLink
				to={`/useful-links`}
				primaryText="Useful links"
				leftIcon={<InfoIcon />}
				onClick={onMenuClick}
			/>
			<Box className="footer-menu">
				<Link to="/release-notes">
					<Typography variant="caption">build {getBuildDate()}</Typography>
				</Link>
			</Box>
		</RAMenu>
	);
};

export default Menu;
