import { useRecordContext, useFieldValue } from 'react-admin';
import { styled } from '@mui/material/styles';
import Rating from '@mui/material/Rating';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import HourglassFullIcon from '@mui/icons-material/HourglassFull';
import { green, orange, red } from '@mui/material/colors';
import { useTheme } from '@mui/material/styles';

const StyledRating = styled(Rating)(({ value }) => {
    const theme = useTheme();

    let color;
    if (value > 3) {
        color = theme.palette.mode === 'dark' ? green[700] : green[300];
    }
    else if (value === 3) {
        color = theme.palette.mode === 'dark' ? orange[700] : orange[300];
    }
    else {
        color = theme.palette.mode === 'dark' ? red[700] : red[300];
    }

    return {
        '& .MuiRating-iconFilled': {
            color,
        }
    };
});

const RatingField = ({ source, ...props }) => {
    const record = useRecordContext(props);
    const value = useFieldValue({ source, record });

    if (!record || !value) {
        return null;
    }

    return (
        <StyledRating
            icon={<HourglassFullIcon fontSize="inherit" />}
            emptyIcon={<HourglassEmptyIcon fontSize="inherit" />}
            getLabelText={(value) => value}
            size="small"
            readOnly
            value={value}
            {...props}
        />
    );
};

export default RatingField;