import React from 'react';
import { useRecordContext, useTranslate } from 'react-admin';
import { green, red } from '@mui/material/colors';

import ChipField from '../../common/fields/ChipField';


const StatusField = ({ source = 'status', size = 'small', ...props }) => {
	const record = useRecordContext();
	const translate = useTranslate();

	const statuses = {
		'new': translate('New'),
		'accepted': translate('Accepted'),
		'refused': translate('Refused')
	};

	if(!record) {
		return null;
	}
	const status = record[source];
	let color;
	switch (status) {
		case 'accepted':
			color = green[100];
			break;
		case 'refused':
			color = red[100];
			break;
		default:
			break;
	}

	if (!status) {
		return null;
	}

	return (
		<ChipField source={status} record={statuses} sx={{ backgroundColor: color }} size={size} {...props} />
	);
};

export default StatusField;
