import React from 'react';
import { useRecordContext, useTranslate, useFieldValue } from 'react-admin';
import { green, orange, red, grey, blue } from '@mui/material/colors';

import ChipField from '../../common/fields/ChipField';


const WorkloadField = ({ source = 'workload', size = 'small', ...props }) => {
    const record = useRecordContext(props);
    const status = useFieldValue({ source });
    const translate = useTranslate();

    if (!record) {
        return null;
    }
    let sx = {};
    switch (status) {
        case 'normal':
            sx = { backgroundColor: (theme) => theme.palette.mode === 'dark' ? green[700] : green[200] };
            break;
        case 'high':
            sx = { backgroundColor: (theme) => theme.palette.mode === 'dark' ? orange[700] : orange[200] };
            break;
        case 'available':
        case 'pending':
            sx = { backgroundColor: (theme) => theme.palette.mode === 'dark' ? blue[700] : blue[100] };
            break;
        case 'low':
        case 'overloaded':
        case 'blocked':
            sx = { backgroundColor: (theme) => theme.palette.mode === 'dark' ? red[700] : red[200] };
            break;
        default:
            sx = { backgroundColor: (theme) => theme.palette.mode === 'dark' ? grey[700] : grey[200] };
            break;
    }

    if (!status) {
        return null;
    }

    const statuses = {
        'available': translate('Available'),
        'pending': translate('Pending'),
        'low': translate('Low'),
        'normal': translate('Normal'),
        'high': translate('High'),
        'overloaded': translate('Overloaded'),
        'blocked': translate('Blocked')
    };

    return (
        <ChipField {...props} size={size} source={status} record={statuses} sx={sx} />
    );
};

export default WorkloadField;
