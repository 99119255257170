import React from 'react';
import { DateInput, TextInput } from 'react-admin';

import Edit from '../common/views/Edit';
import SimpleForm from '../common/forms/SimpleForm';
import RichTextInput from '../common/inputs/RichTextInput';


const MonthlyEdit = (props) => (
    <Edit {...props}>
        <SimpleForm>
            <DateInput source="date" />
            <RichTextInput source="priorities" />
            <RichTextInput source="tasks" />
            <TextInput source="notes" multiline fullWidth rows="4" sx={{ '&&': { minWidth: '100%!important' } }} />
        </SimpleForm>
    </Edit>
);

export default MonthlyEdit;
