import React from 'react';
import { useRecordContext, useTranslate, useFieldValue } from 'react-admin';

import ChipField from '../../common/fields/ChipField';


const CategoryField = ({ source = 'category', size = 'small', variant = 'outlined', ...props }) => {
    const record = useRecordContext(props);
    const translate = useTranslate();
    const value = useFieldValue({ source, ...props });

    const categories = {
        'news': translate('News')
    };

    if (!record || !value) {
        return null;
    }

    return (
        <ChipField {...props} source={value} record={categories} size={size} variant={variant} />
    );
};

export default CategoryField;
