import React from 'react';
import { TextInput, ReferenceInput, AutocompleteInput, DateInput, usePermissions, useGetList, useGetIdentity, useTranslate } from 'react-admin';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import moment from 'moment';

import Create from '../common/views/Create';
import SimpleForm from '../common/forms/SimpleForm';
import RichTextInput from '../common/inputs/RichTextInput';
import RatingInput from '../common/inputs/RatingInput';
import MultilineTextField from '../common/fields/MultilineTextField';
import DateField from '../common/fields/DateField';
import RichTextField from '../common/fields/RichTextField';
import RatingField from '../common/fields/RatingField';
import WorkloadInput from './inputs/WorkloadInput';
import WorkloadField from './fields/WorkloadField';

const userOptionText = choice => choice?.fullname ? choice.fullname : '';


const drawerSx = {
    width: 400,
    flexShrink: 0,
    '& .MuiDrawer-paper': {
        width: 400,
        boxSizing: 'border-box',
        padding: '15px',
        paddingTop: '55px'
    },
    '@media (max-width: 760px)': {
        display: 'none'
    }
};

const WeeklyCreate = (props) => {
    const { data: account } = useGetIdentity();
    const { permissions } = usePermissions();
    const translate = useTranslate();
    const { data: lastWeeklies } = useGetList(
        'weeklies',
        {
            pagination: { page: 1, perPage: 3 },
            sort: { field: 'date', order: 'DESC' },
            filter: { user_id: account?.id }
        }
    );

    return (
        <Box display="flex">
            <Box sx={{ flexGrow: 1, marginBottom: '15px' }}>
                <Create {...props}>
                    <SimpleForm>
                        <DateInput source="date" inputProps={{ min: moment().startOf('isoWeek').subtract(7, 'days').format('YYYY-MM-DD'), step: 7 }} />
                        {permissions?.includes('is_admin') && (
                            <ReferenceInput source="user_id" reference="users" sort={{ field: 'id', order: 'ASC' }}>
                                <AutocompleteInput optionText={userOptionText} size="small" />
                            </ReferenceInput>
                        )}
                        <WorkloadInput source="workload" />
                        <RatingInput source="occupancy_rate" helperText="Estimate the number of days you are busy during the week" />
                        <RichTextInput source="priorities" />
                        <RichTextInput source="tasks" />
                        <TextInput source="notes" multiline fullWidth rows="4" sx={{ '&&': { minWidth: '100%!important' } }} />
                    </SimpleForm>
                </Create>
            </Box>
            {lastWeeklies?.length > 0 && (
                <Drawer open variant="persistent" anchor="right" sx={drawerSx}>
                    <Typography variant="h5">{translate('Last weekly')} <WorkloadField record={lastWeeklies[0]} /> <DateField source="created_at" record={lastWeeklies[0]} /> </Typography>
                    <RatingField source="occupancy_rate" label="Occupancy rate" record={lastWeeklies[0]} />

                    <Box sx={{ margin: '15px 0' }}>
                        <Typography variant="h6">{translate('Priorities')}</Typography>
                        <RichTextField source="priorities" record={lastWeeklies[0]} />
                    </Box>

                    <Divider />

                    <Box sx={{ margin: '15px 0' }}>
                        <Typography variant="h6">{translate('Tasks')}</Typography>
                        <RichTextField source="tasks" record={lastWeeklies[0]} />
                    </Box>

                    {lastWeeklies[0].notes && (
                        <>
                            <Divider />
                            <MultilineTextField source="notes" record={lastWeeklies[0]} />
                        </>
                    )}
                </Drawer>
            )}
        </Box>
    );
};

export default WeeklyCreate;
