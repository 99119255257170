import React from 'react';
import { AutocompleteInput, useRecordContext } from 'react-admin';

const choices = [
	{ id: 'news', name: 'News' }
];

const CategoryInput = ({ classes, source = 'category', ...props }) => {
	const record = useRecordContext();

	return (
		<AutocompleteInput choices={choices} source={source} record={record} {...props} />
	);
};

export default CategoryInput;
